query getComplaintReport(
  $distributor: String
  $from: Date
  $to: Date
  $page: Int
  $limit: Int
) {
  getComplaintReport(
    distributor: $distributor
    from: $from
    to: $to
    page: $page
    limit: $limit
  ) {
    currentPage
    total
    complaintReport {
      edition {
        id
        editionLabel
      }
      reasons {
        reason
        count
      }
    }
  }
}
