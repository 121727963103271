query GetEconomySalesReport($organization: String, $month: String, $paymentProvider: String, $site: String) {
  getEconomySalesReport(organization: $organization, month: $month, paymentProvider: $paymentProvider, site: $site) {
    sites {
      name
      quantity
      salesInclVat
      vat6
      vat25
      vatTotal
      salesExclVat
      paymentProviders {
        name
        quantity
        salesInclVat
        vat6
        vat25
        vatTotal
        salesExclVat
        salesGroups {
          name
          quantity
          salesInclVat
          vat6
          vat25
          vatTotal
          salesExclVat
          regionGroups {
            name
            quantity
            salesInclVat
            vat6
            vat25
            vatTotal
            salesExclVat
          }
        }
      }
    }
    total {
      quantity
      salesInclVat
      vat6
      vat25
      vatTotal
      salesExclVat
      paymentProviders {
        name
        quantity
        salesInclVat
        vat6
        vat25
        vatTotal
        salesExclVat
      }
    }
  }
}
