import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useCallback, useEffect, useState } from 'react';
import { sites } from 'trhub-utils';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorMessage from '~/components/ErrorMessage';
import toOptions from '~/utils/toOptions';

import mutation from './_AddWatchGroup.gql';
import GetWatchGroups from './_GetWatchGroups.gql';
import WatchGroups from './WatchGroups';

export default function UserSettings() {
  const [errorMessage, setErrorMessage] = useState(null);
  const [action, { error }] = useMutation(mutation);

  const {
    data: watchGroupsData,
    loading: watchGroupsLoading,
    error: watchGroupsError,
    refetch,
  } = useQuery(GetWatchGroups);
  const [watchGroups, setWatchGroups] = useState([]);

  useEffect(() => {
    if (watchGroupsData) {
      setWatchGroups([...watchGroupsData.list.items]);
    }
  }, [watchGroupsData]);

  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      await action({
        variables: {
          input: {
            name: values.name,
            site: values.site,
          },
        },
      });

      setSubmitting(false);
    },
    [action],
  );

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateGroup = async submitForm => {
    try {
      await submitForm();
      await refetch();
      handleClose();
    } catch (error) {
      setErrorMessage(error);
      handleClose();
    }
  };

  if (watchGroupsLoading) {
    return (
      <Box my={3}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Breadcrumb name="Bevakningsgrupper" />
      {watchGroupsError && <ErrorMessage error={watchGroupsError} />}
      {errorMessage && <ErrorMessage error={errorMessage} />}
      <Paper>
        <Box display="flex" justifyContent="space-between">
          <Box flex={1} p={3}>
            <Typography component="h2" variant="h6" color="primary">
              Bevakningsgrupper
            </Typography>
            <WatchGroups key={watchGroups.length} watchGroups={watchGroups} />
          </Box>
        </Box>
        <Formik
          initialValues={{
            name: '',
            site: '',
          }}
          onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ submitForm, values }) => (
            <Form
              css={{
                '.MuiFormControl-root': {
                  width: '100%',
                },
              }}
            >
              {error && <ErrorMessage error={error} />}
              <Box pl={3} pb={3}>
                <Button
                  sx={theme => ({
                    backgroundColor: theme.palette.info.main,
                    color: theme.palette.text.main,
                    '&:hover': {
                      background: theme.palette.info.dark,
                    },
                  })}
                  onClick={handleClickOpen}
                  variant="contained"
                >
                  Ny bevakningsgrupp
                </Button>
              </Box>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Ny bevakningsgrupp</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Ange namn för den nya bevakningsgruppen.
                  </DialogContentText>
                  <Box pt={2}>
                    <Field
                      component={TextField}
                      name="name"
                      label="Namn"
                      variant="outlined"
                      fullWidth
                      value={values.name}
                    />
                  </Box>
                  <Box pt={2}>
                    <Field
                      component={TextField}
                      select
                      name="site"
                      label="Sajt"
                      variant="outlined"
                      fullWidth
                    >
                      {toOptions(sites).map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.text}
                        </MenuItem>
                      ))}
                    </Field>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Avbryt
                  </Button>
                  <Button
                    onClick={() => handleCreateGroup(submitForm)}
                    sx={theme => ({
                      color: theme.palette.error.main,
                    })}
                  >
                    Skapa grupp
                  </Button>
                </DialogActions>
              </Dialog>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
}
