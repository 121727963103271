query GetOrdersList($filter: OrderFilters, $page: Int, $limit: Int) {
  orders: getOrders(filter: $filter, page: $page, limit: $limit) {
    currentPage
    total
    items: orders {
      id
      created
      label
      orderStatus
      paymentStatus
      paymentMethod
      totalPrice
      user {
        id
        email
        name
      }
      lineItems {
        type
        product {
          name
        }
        price
      }
      orderBuilder {
        name
      }
    }
  }
}
