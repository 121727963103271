query GetSubscriptionStats(
  $site: String
  $productIds: [String]
  $from: Date
  $to: Date
  $dateType: String
  $page: Int
  $limit: Int
) {
  getSubscriptionStats(
    site: $site
    productIds: $productIds
    from: $from
    to: $to
    dateType: $dateType
    page: $page
    limit: $limit
  ) {
    currentPage
    total
    dateType
    reports {
      date
      newSubscribers
      reactivatedEndedSubscribers
      inactiveSubscribers
      reactivatedPassiveSubscribers
      cancelledSubscribers
      endedSubscribers
      activeSubscribers
      churn
      totalSubscribers
    }
    summary {
      newSubscribers
      reactivatedEndedSubscribers
      inactiveSubscribers
      reactivatedPassiveSubscribers
      cancelledSubscribers
      endedSubscribers
      activeSubscribers
      churn
      totalSubscribers
    }
  }
}
