query GetDebtReports($filter: DebtReportFilters, $page: Int, $limit: Int) {
  getDebtReports(filter: $filter, page: $page, limit: $limit) {
    currentPage
    total
    reports {
      id
      date
      productName
      netDebt
      totalDebt
      vat
    }
  }
}
