mutation AddSubscriptionToPayee(
  $payee: String!
  $userId: String!
  $productId: String!
) {
  addSubscriptionToPayee(
    payee: $payee
    userId: $userId
    productId: $productId
  ) {
    ... on Subscription {
      id
    }
    ... on Error {
      error
    }
  }
}
