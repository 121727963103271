mutation CreateUserOrder(
  $user: String!
  $product: String!
  $campaign: String
  $deliveryMethod: String!
) {
  createUserOrder(
    user: $user
    product: $product
    campaign: $campaign
    deliveryMethod: $deliveryMethod
  ) {
    ... on Order {
      id
    }
    ... on Error {
      error
    }
  }
}
