query UnhandledPayments(
  $filter: DeviationReportFilters
  $page: Int
  $limit: Int
) {
  list: getUnhandledPayments(filter: $filter, page: $page, limit: $limit) {
    currentPage
    total
    totalAmount
    items: payments {
      id
      organization
      date
      amount
      refund
      payload {
        Description
        Reference
      }
    }
  }
}
