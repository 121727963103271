query GetMossReport($month: String, $quarter: String) {
  getMossReport(month: $month, quarter: $quarter) {
    countries {
      name
      vatPercentage
      salesExclVat
      vatTotal
      salesInclVat
      euroExchangeRate
      vatTotalInEuro
      salesExclVatInEuro
    }
  }
}
