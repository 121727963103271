mutation GiveTicketToUser(
  $userId: String!
  $productId: String!
  $punches: Int!
) {
  giveTicketToUser(userId: $userId, productId: $productId, punches: $punches) {
    ... on Ticket {
      id
    }
    ... on Error {
      error
    }
  }
}
