query GetSubscriptionsByUser(
  $user: String
  $payee: String
  $page: Int
  $limit: Int
) {
  getSubscriptionsByUser(
    user: $user
    payee: $payee
    page: $page
    limit: $limit
  ) {
    currentPage
    total
    subscriptions {
      id
      product {
        name
      }
      user {
        id
        label
      }
      startDate
      isActive
      isPaused
      endDate
    }
  }
}
