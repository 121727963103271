query GetDeviatedOrders(
  $filter: DeviationReportFilters
  $sort: Sort
  $page: Int
  $limit: Int
) {
  list: getDeviationReport(
    filter: $filter
    sort: $sort
    page: $page
    limit: $limit
  ) {
    currentPage
    total
    totalAmount
    items: orders {
      id
      created
      orderStatus
      label
      totalPrice
      paidAmount
      paymentStatus
      deviationInfo {
        clearance {
          amount
          date
          userName
        }
        status
      }
      payments {
        date
        source
      }
      user {
        id
        name
      }
    }
  }
}
