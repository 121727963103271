query GetIssues($filter: IssueFilters, $page: Int, $limit: Int) {
  getIssues(filter: $filter, page: $page, limit: $limit) {
    currentPage
    total
    issues {
      id
      dispatcher
      description
      status
      created
      assignee
    }
  }
}
