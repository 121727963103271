query GetTermsList($filter: TermsFilter, $sort: Sort, $page: Int, $limit: Int) {
  list: getTermsList(filter: $filter, sort: $sort, page: $page, limit: $limit) {
    currentPage
    total
    items: terms {
      id
      version
      name
      text
      published
      updated
      status
    }
  }
}
