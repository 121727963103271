import { useMutation, useQuery } from '@apollo/client';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  TextField,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import ErrorMessage from '~/components/ErrorMessage';

import GetUsers from '../_GetUsers.gql';
import mutation from './_EditWatchGroup.gql';

export default function WatchGroups({ watchGroups }) {
  const [userSearch, setUserSearch] = useState('');
  const refetchUser = useCallback(search => {
    setUserSearch(search);
  }, []);

  const [action, { error: mutationError }] = useMutation(mutation);

  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      try {
        await Promise.all(
          values.map(watchGroup =>
            action({
              variables: {
                id: watchGroup.id,
                input: {
                  name: watchGroup.name,
                  site: watchGroup.site,
                  users: watchGroup.users.map(user => String(user.id)),
                },
              },
            }),
          ),
        );
      } finally {
        setSubmitting(false);
      }
    },
    [action],
  );

  const { data, loading, error } = useQuery(GetUsers, {
    variables: {
      filter: {
        search: userSearch,
        role: 'admin',
      },
    },
  });
  const users = data?.list?.items ?? [];

  if (!watchGroups || watchGroups.length === 0) {
    return (
      <Box my={3}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {error && <ErrorMessage error={error} />}
      {mutationError && <ErrorMessage error={mutationError} />}

      <Formik
        initialValues={watchGroups.map(watchGroup => ({
          id: watchGroup.id,
          name: watchGroup.name,
          site: watchGroup.site,
          users: watchGroup.users,
        }))}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ submitForm, isSubmitting, setFieldValue, errors, values }) => (
          <Form
            css={({ theme }) => ({
              '> * + *': {
                marginTop: theme.spacing(2),
              },
              '> *': {
                width: '100%',
              },
              '> .MuiFormControl-root': {
                backgroundColor: theme.palette.background.default,
              },
            })}
          >
            {values.map((watchGroup, index) => (
              <Box key={`${watchGroup.id}-${index}`} pt={1}>
                <Field
                  multiple
                  value={watchGroup.users}
                  noOptionsText={
                    loading ? 'Laddar in användare...' : 'Inga träffar.'
                  }
                  name={`[${index}].users`}
                  component={Autocomplete}
                  filterSelectedOptions
                  options={users}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  getOptionKey={option => option.id.toString()}
                  getOptionLabel={option => option.name}
                  onInputChange={async (_, value) => {
                    refetchUser(value);
                  }}
                  onChange={(_, value) =>
                    setFieldValue(`[${index}].users`, value)
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={watchGroup.name}
                      variant="outlined"
                      error={errors.users != null}
                    />
                  )}
                />
                {isSubmitting && <LinearProgress />}
              </Box>
            ))}
            <Box pt={2}>
              {isSubmitting && <LinearProgress />}
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Spara ändringar
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}

WatchGroups.propTypes = {
  watchGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      site: PropTypes.string.isRequired,
      users: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
};
