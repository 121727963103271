import { Settings } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { userRoles } from 'trhub-utils';

import SelectField from '~/components/Select';
import useAuthUser from '~/hooks/useAuthUser';
import useQueryParams from '~/hooks/useQueryParams';
import shallowEqual from '~/utils/shallowEqual';

import UserListResults from './UserListResult';

const initialValues = {
  search: '',
  role: '',
  onlyCollectionPayee: false,
  card: '',
  jokerAlias: '',
};

export default function UserList() {
  const { pathname } = useLocation();
  const { isAdmin } = useAuthUser();

  const [selectedRows, setSelectedRows] = useState([]);

  const { state, params, handleChange, clear } = useQueryParams(initialValues);

  const handleSelectRows = useCallback(values => {
    setSelectedRows(values);
  }, []);

  return (
    <>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          gridAutoFlow="column"
          columnGap="10px"
          marginTop="-10px"
          sx={{
            '.MuiFormControl-root': {
              minWidth: '120px',
            },
          }}
        >
          <TextField
            label="Sök"
            variant="outlined"
            value={state.search}
            onChange={e => handleChange('search', e.target.value)}
          />

          <TextField
            label="Joker alias"
            variant="outlined"
            value={state.jokerAlias}
            onChange={e => handleChange('jokerAlias', e.target.value)}
          />

          <SelectField
            label="Roll"
            value={state.role}
            onChange={e => handleChange('role', e.target.value)}
            options={[
              { value: '', label: 'Alla' },
              ...Object.entries(userRoles).map(([value, label]) => ({
                value,
                label,
              })),
            ]}
          />

          <TextField
            label="Kortnummer"
            variant="outlined"
            value={state.card || ''}
            onChange={e => handleChange('card', e.target.value)}
            type="number"
            inputProps={{
              maxLength: 4,
            }}
          />

          <FormControlLabel
            label="Endast samlingsbetalare"
            control={
              <Checkbox
                checked={state.onlyCollectionPayee}
                onChange={_ =>
                  handleChange(
                    'onlyCollectionPayee',
                    !state.onlyCollectionPayee,
                  )
                }
              />
            }
          />

          {!shallowEqual(initialValues, state) && (
            <Button
              color="primary"
              sx={{
                textTransform: 'none',
              }}
              onClick={clear}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>

        <Box columnGap="10px" display="grid" gridAutoFlow="column">
          {isAdmin && (
            <Button
              component={Link}
              to={`${pathname}/settings`}
              color="secondary"
              variant="contained"
              startIcon={<Settings />}
            >
              Inställningar
            </Button>
          )}
        </Box>
      </Box>

      <UserListResults
        params={params}
        selectedRows={selectedRows}
        handleSelectRows={handleSelectRows}
      />
      <Box py={3}>
        <Button
          color="primary"
          disabled={selectedRows.length < 2}
          variant="contained"
        >
          Sammanfoga användare
        </Button>
      </Box>
    </>
  );
}
