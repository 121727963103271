query getUsedGiftCodePurchaseOrders($filter: GiftCodeOrderFilters, $page: Int, $limit: Int) {
  list: getUsedGiftCodePurchaseOrders(filter: $filter, page: $page, limit: $limit) {
    currentPage
    total
    items: orders {
      giftCode
      created
      label
      totalPrice
      giftExpiryDate
      giftActivated
      giftOrder {
        id
      }
      lineItems {
        subscription {
          id
        }
      }
    }
  }
}