import { gql, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { createContext, useEffect } from 'react';

import ErrorMessage from '~/components/ErrorMessage';

const currentUser = gql`
  query CurrentUser {
    refreshUser {
      id
      name
      roles
      isAdmin
    }
  }
`;

const Center = ({ children }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="100vh"
  >
    {children}
  </Box>
);

Center.propTypes = {
  children: PropTypes.node.isRequired,
};
export const UserContext = createContext(null);

function Auth({ children }) {
  const { data, loading, error } = useQuery(currentUser);
  const user = data?.refreshUser;

  useEffect(() => {
    if (
      user?.roles.some(role => ['admin', 'customer-service'].includes(role))
    ) {
      Sentry.setUser({ id: user.id, email: user.email, username: user.name });
    }
  }, [user]);

  useEffect(() => {
    if (error?.networkError?.statusCode === 401) {
      window.location.href = `/oauth/authorize?dest=${encodeURIComponent(
        window.location.href,
      )}`;
    }
  }, [error?.networkError?.statusCode]);

  if (loading) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (
    !user.roles.includes('admin') &&
    !user.roles.includes('customer-service') &&
    !user.roles.includes('banprogram:distributor')
  ) {
    return (
      <Center>
        <Card css={{ minWidth: '300px' }}>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Du saknar admin behörighet
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              component="a"
              href={`${
                import.meta.env.VITE_AUTH_URL
              }/logout?dest=${encodeURIComponent(window.location.href)}`}
              color="primary"
            >
              Logga ut
            </Button>
          </CardActions>
        </Card>
      </Center>
    );
  }

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

Auth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Auth;
