query GetTicketsByUser($user: String!, $page: Int, $limit: Int) {
  getTicketsByUser(user: $user, page: $page, limit: $limit) {
    currentPage
    total
    tickets {
      id
      site
      betType
      remaining
      punches
      usage {
        id
        name
        roundDate
      }
    }
  }
}
