import { useQuery } from '@apollo/client';
import { ChevronLeft, ExitToApp, Menu } from '@mui/icons-material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  AppBar,
  Badge,
  Box,
  CircularProgress,
  Collapse,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { accessMap } from 'trhub-utils';

import { Breadcrumbs } from '~/components/Breadcrumbs';
import ErrorMessage from '~/components/ErrorMessage';
import useAuthUser from '~/hooks/useAuthUser';

import query from './_GetBadgeCounts.gql';
import menus from './menu';

const drawerWidth = 240;

export default function Layout({ children }) {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const [open, setOpen] = useState(mdUp);
  const { pathname } = useLocation();

  // Find out if any submenu should be open on mount
  const activeItem = [...menus.primary, ...menus.secondary].find(item =>
    pathname.startsWith(item.path),
  );

  const [nestedListExpanded, setNestedListExpanded] = useState(
    activeItem
      ? {
          [activeItem.text]: true,
        }
      : {},
  );

  const handleNestedListExpanded = useCallback(key => {
    setNestedListExpanded(previous => ({ ...previous, [key]: !previous[key] }));
  }, []);

  const openDrawer = useCallback(() => setOpen(true), []);
  const closeDrawer = useCallback(() => setOpen(false), []);

  const { error, data } = useQuery(query);

  const { isAdmin, roles } = useAuthUser();

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!data) {
    return (
      <Box
        display="flex"
        width="100vw"
        height="100vh"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box display="flex">
      <AppBar
        open={open}
        position="fixed"
        sx={({ breakpoints, transitions }) => ({
          transition: transitions.create(['margin', 'width'], {
            easing: transitions.easing[open ? 'easeOut' : 'sharp'],
            duration:
              transitions.duration[open ? 'leavingScreen' : 'enteringScreen'],
          }),
          width: open ? `calc(100% - ${drawerWidth}px)` : '100%',
          marginLeft: open ? `${drawerWidth}px` : 0,

          [breakpoints.up('lg')]: {
            marginLeft: `${drawerWidth}px`,
          },
        })}
      >
        <Toolbar>
          <Box sx={{ display: { lg: 'none' } }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={openDrawer}
              edge="start"
              open={open}
              sx={theme => ({
                marginRight: theme.spacing(2),
                display: open ? 'none' : 'inline-flex',
              })}
              size="large"
            >
              <Menu />
            </IconButton>
          </Box>
          <Typography variant="h6" noWrap>
            TR Hub Admin
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={lgUp ? 'permanent' : mdUp ? 'persistent' : 'temporary'}
        anchor="left"
        open={open}
        onClose={closeDrawer}
      >
        <Box width={drawerWidth}>
          <Box
            sx={theme => ({
              display: 'flex',
              alignItems: 'center',
              padding: theme.spacing(0, 1),
              justifyContent: 'flex-end',
              ...theme.mixins.toolbar,
            })}
          >
            <Box sx={{ display: { lg: 'none' } }}>
              <IconButton onClick={closeDrawer} size="large">
                <ChevronLeft />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          <List>
            {menus.primary
              .filter(
                item =>
                  item.access &&
                  accessMap[item.access].some(x => roles.includes(x)),
              )
              .map(item => (
                <ListItem
                  button
                  key={item.path}
                  component={Link}
                  to={item.path}
                  selected={pathname.includes(item.path)}
                >
                  <ListItemIcon>
                    <item.icon />
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              ))}
          </List>
          {isAdmin && (
            <>
              <Divider />
              <List>
                {menus.secondary.map((item, index) => (
                  <div key={index}>
                    <>
                      <ListItem
                        selected={pathname === item.path}
                        button
                        component={!item.subMenu ? Link : undefined}
                        onClick={
                          item.subMenu
                            ? () => handleNestedListExpanded(item.text)
                            : undefined
                        }
                        to={item.path}
                      >
                        <ListItemIcon>
                          <item.icon />
                        </ListItemIcon>
                        <ListItemText primary={item.text} />
                        {item.subMenu &&
                          (nestedListExpanded[item.text] ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          ))}
                        {item.badge && (
                          <Badge
                            overlap="rectangular"
                            badgeContent={data[item.badge].total}
                            color="error"
                            sx={theme => ({
                              marginRight: theme.spacing(2),
                            })}
                          />
                        )}
                      </ListItem>
                      {item.subMenu && (
                        <Collapse
                          in={nestedListExpanded[item.text]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {item.subMenu.map(subItem => (
                              <ListItem
                                key={subItem.path}
                                button
                                component={Link}
                                to={item.path + subItem.path}
                                selected={pathname === item.path + subItem.path}
                                sx={theme => ({
                                  paddingLeft: theme.spacing(4),
                                })}
                              >
                                <ListItemIcon>
                                  <subItem.icon />
                                </ListItemIcon>
                                <ListItemText primary={subItem.text} />
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      )}
                    </>
                  </div>
                ))}
              </List>
            </>
          )}
          <Divider />
          <List>
            <ListItem
              button
              component="a"
              href={`${import.meta.env.VITE_AUTH_URL}/logout?dest=${encodeURI(
                window.location.href,
              )}`}
            >
              <ListItemIcon>
                <ExitToApp />
              </ListItemIcon>
              <ListItemText primary="Logga ut" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={({ breakpoints, transitions }) => ({
          flexGrow: 1,
          maxWidth: '100%',
          height: '100vh',

          [breakpoints.up('md')]: {
            marginLeft: open ? `${drawerWidth}px` : 0,
            maxWidth: open ? `calc(100% - ${drawerWidth}px)` : '100%',
            transition: transitions.create('margin', {
              easing: transitions.easing[open ? 'easeOut' : 'sharp'],
              duration:
                transitions.duration[open ? 'leavingScreen' : 'enteringScreen'],
            }),
          },
          [breakpoints.up('lg')]: {
            marginLeft: `${drawerWidth}px`,
            maxWidth: '100%',
          },
        })}
      >
        <Container
          maxWidth="lg"
          sx={theme => ({
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
          })}
        >
          <Box
            sx={theme => ({
              ...theme.mixins.toolbar,
            })}
          />
          <Breadcrumbs />
          {children}
        </Container>
      </Box>
    </Box>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
