query GetDistributionsByUser($user: String!, $page: Int, $limit: Int) {
  getDistributionsByUser(user: $user, page: $page, limit: $limit) {
    currentPage
    total
    distributions {
      id
      edition {
        id
        sku
        editionLabel
        deliveryDate
      }
      distributionDate
      distributor
      error
      complaint {
        reason
      }
    }
  }
}
