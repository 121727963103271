query getGiftCodeDebt($filter: GiftCodeDebtFilters, $limit: Int) {
  list: getGiftCodeDebt(filter: $filter, limit: $limit) {
    items: items {
      product {
        id
        name
      }
      debt
    }
  }
}
