query GetActiveCampaigns($site: String, $offerUponCancellation: Boolean) {
  campaigns: getActiveCampaigns(
    site: $site
    offerUponCancellation: $offerUponCancellation
  ) {
    id
    name
    site
    products {
      product {
        id
        name
      }
    }
  }
}
