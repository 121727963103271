query GetDevicesByUser($user: String!, $page: Int, $limit: Int) {
  getDevicesByUser(user: $user, page: $page, limit: $limit) {
    currentPage
    total
    userDevices {
      id
      created
      fcmToken
    }
  }
}
