query GetEditions(
  $sort: Sort
  $filter: EditionFilters
  $page: Int
  $limit: Int
) {
  list: getEditions(sort: $sort, filter: $filter, page: $page, limit: $limit) {
    currentPage
    total
    items: editions {
      id
      editionLabel
      sku
      deliveryDate
      distributionDate
      lastOrderDate
      productionDate
      distributionCount
    }
  }
}
