query GetMissingPaymentOrders(
  $filter: DeviationReportFilters
  $page: Int
  $limit: Int
) {
  list: getMissingPaymentOrders(filter: $filter, page: $page, limit: $limit) {
    currentPage
    total
    totalAmount
    items: orders {
      id
      orderStatus
      created
      label
      paymentMethod
      totalPrice
      user {
        id
        email
      }
    }
  }
}
