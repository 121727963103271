query GetCancellationOffer($user: String!, $subscription: String!) {
  getCancellationOffer(user: $user, subscription: $subscription) {
    id
    name
    price
    expireDate
    site
    duration
    presentation {
      infoText
      headline
    }
  }
}
