query GetCardsByUser($user: String!, $organization: String) {
  cards: getCards(user: $user, organization: $organization) {
    id
    maskedPan
    brand
    expiryDate
    isDefault
    organization
  }
}
