mutation PauseDistributionSubscription(
  $id: String!
  $distributionPauseStartDate: Date!
  $distributionPauseEndDate: Date!
  $user: String!
) {
  pauseDistributionSubscription(
    id: $id
    distributionPauseStartDate: $distributionPauseStartDate
    distributionPauseEndDate: $distributionPauseEndDate
    user: $user
  ) {
    id
  }
}
