query GetRefunds($sort: Sort, $filter: RefundFilters, $page: Int, $limit: Int) {
  list: getRefunds(sort: $sort, filter: $filter, page: $page, limit: $limit) {
    currentPage
    total
    items: refunds {
      id
      order {
        id
        label
      }
      amount
      attestants {
        id
        name
      }
      status
      paymentMethod
      user {
        id
        name
      }
      created
    }
  }
}
