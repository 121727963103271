mutation CancelSubscription(
  $id: String!
  $endDate: Date!
  $unsubscribeReason: UnsubscribeReasonInput!
  $user: String!
  $noCancellationMail: Boolean
) {
  cancelSubscription(
    id: $id
    endDate: $endDate
    unsubscribeReason: $unsubscribeReason
    user: $user
    noCancellationMail: $noCancellationMail
  ) {
    id
    endDate
  }
}
