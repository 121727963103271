mutation GiveSubscriptionToUser(
  $userId: String!
  $productId: String!
  $endDate: Date!
) {
  giveSubscriptionToUser(
    userId: $userId
    productId: $productId
    endDate: $endDate
  ) {
    ... on Subscription {
      id
    }
    ... on Error {
      error
    }
  }
}
