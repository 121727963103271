mutation AddCampaignToSubscription(
  $user: ID!
  $subscription: ID!
  $campaign: ID!
  $reason: AddCampaignReasonType!
) {
  addCampaignToSubscription(
    user: $user
    subscription: $subscription
    campaign: $campaign
    reason: $reason
  ) {
    id
    status
    updatedAt
    campaign {
      id
      name
      type
    }
    subscription {
      id
      status
    }
  }
}
