query GetCanceledSubscriptionsReport(
  $page: Int!
  $limit: Int!
  $filter: SubscriptionsFilter
) {
  list: getSubscriptions(
    page: $page
    limit: $limit
    filter: $filter
    sort: { sort: "desc", field: "endDate" }
  ) {
    currentPage
    total
    items: subscriptions {
      id
      product {
        name
        site
      }
      user {
        mobile
        customerNumber
        email
        name
      }
      startDate
      endDate
      paymentMethod
      unsubscribeReason {
        reason
        text
      }
      eventLog {
        message
      }
    }
  }
}
