query GetDistributions(
  $edition: String!
  $filter: DistributionFilters
  $page: Int
  $limit: Int
) {
  getDistributions(
    edition: $edition
    filter: $filter
    page: $page
    limit: $limit
  ) {
    currentPage
    total
    distributions {
      id
      user {
        id
        name
      }
      address {
        address
        postalCode
        city
      }
      distributionDate
      distributor
      quantity
      error
    }
  }
}
