mutation CreateCollectionPayeeOrder($user: String!, $deliveryMethod: String!) {
  createCollectionPayeeOrder(user: $user, deliveryMethod: $deliveryMethod) {
    ... on Order {
      id
    }
    ... on Error {
      error
    }
  }
}
