query GetProducts(
  $filter: ProductFilter
  $sort: Sort
  $page: Int
  $limit: Int
) {
  list: getProducts(sort: $sort, filter: $filter, page: $page, limit: $limit) {
    currentPage
    total
    items: products {
      id
      name
      type
      sku
      price
      vat
      site
      paymentInterval
    }
  }
}
