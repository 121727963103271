query getUnsubscriptionReports(
  $productId: String
  $from: Date
  $to: Date
  $page: Int
  $limit: Int
) {
  getUnsubscriptionReports(
    productId: $productId
    from: $from
    to: $to
    page: $page
    limit: $limit
  ) {
    currentPage
    total
    unsubscriptionReport {
      date
      expectation
      limitedTime
      tooExpensive
      passedDueDate
      other
    }
  }
}
