query GetCampaigns(
  $filter: CampaignFilter
  $sort: Sort
  $page: Int
  $limit: Int
) {
  list: getCampaigns(filter: $filter, page: $page, limit: $limit, sort: $sort) {
    currentPage
    total
    items: campaigns {
      id
      name
      code
      productsLabel
      type
      price
      usage
      products {
        product {
          name
        }
        percentage
      }
    }
  }
}
