query GetAccessesByUser($user: String!, $page: Int, $limit: Int) {
  getAccessesByUser(user: $user, page: $page, limit: $limit) {
    currentPage
    total
    accesses {
      product {
        id
        name
      }
    }
  }
}
