query GetBettipStats(
  $site: String
  $betType: String
  $sortField: String
  $page: Int
  $limit: Int
) {
  getBetTipStatistics(
    filter: { site: $site, betType: $betType }
    sort: { field: $sortField, sort: "desc" }
    page: $page
    limit: $limit
  ) {
    currentPage
    total
    products {
      id
      name
      price
      sales {
        regular
        campaign
        free
        ticket
        total
        revenue
      }
    }
  }
}
