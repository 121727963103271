query GetOrderBuilders(
  $filter: OrderBuilderFilters
  $sort: Sort
  $page: Int
  $limit: Int
) {
  list: getOrderBuilders(
    filter: $filter
    sort: $sort
    page: $page
    limit: $limit
  ) {
    currentPage
    total
    items: orderBuilders {
      id
      status
      name
      created
      sendDate
    }
  }
}
