mutation AddWatchGroup($input: WatchGroupInput!) {
  addWatchGroup(input: $input) {
    ... on WatchGroup {
      id
      name
      site
    }
    ... on Error {
      error
    }
  }
}
