import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';

import ErrorMessage from '~/components/ErrorMessage';

const getErrorView = ({ error, componentStack }) => {
  const err = new Error(`${error}\n${componentStack}`);
  return <ErrorMessage err={err} />;
};

const ErrorBoundary = ({ children, showError = true }) => (
  <Sentry.ErrorBoundary fallback={showError ? getErrorView : undefined}>
    {children}
  </Sentry.ErrorBoundary>
);

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  showError: PropTypes.bool,
};

ErrorBoundary.displayName = 'AppErrorBoundary';

export default ErrorBoundary;
