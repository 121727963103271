mutation EditWatchGroup($id: String!, $input: WatchGroupInput!) {
  editWatchGroup(id: $id, input: $input) {
    ... on WatchGroup {
      id
      name
      site
      users {
        id
        name
      }
    }
    ... on Error {
      error
    }
  }
}
