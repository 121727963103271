query GetAllSalesReports(
  $type: String
  $dateType: String
  $productId: String!
  $site: String
  $betType: String
  $from: Date
  $to: Date
  $page: Int
  $limit: Int
  $fetchProduct: Boolean!
) {
  getProduct(id: $productId) @include(if: $fetchProduct) {
    id
    name
  }
  getSalesReports(
    type: $type
    dateType: $dateType
    productId: $productId
    site: $site
    betType: $betType
    from: $from
    to: $to
    page: $page
    limit: $limit
  ) {
    currentPage
    total
    type
    totalReport {
      total {
        totalOrders
        totalVatSixPct
        totalVatTwentyFivePct
        totalVat
        totalIncomeExcludingVat
        totalIncomeIncludingVat
        shippingOrders
        shippingVatSixPct
        shippingVatTwentyFivePct
        shippingVat
        shippingIncomeExcludingVat
        shippingIncomeIncludingVat
      }
    }
    reports {
      id
      date
      product {
        id
        name
        sku
      }
      site
      total {
        totalOrders
        totalVatSixPct
        totalVatTwentyFivePct
        totalVat
        totalIncomeExcludingVat
        totalIncomeIncludingVat
        shippingOrders
        shippingVatSixPct
        shippingVatTwentyFivePct
        shippingVat
        shippingIncomeExcludingVat
        shippingIncomeIncludingVat
      }
    }
  }
}
