query GetUsers($page: Int, $limit: Int, $sort: Sort, $filter: UserFilter) {
  list: getUsers(sort: $sort, filter: $filter, page: $page, limit: $limit) {
    currentPage
    total
    items: users {
      id
      uid
      name
      label
      initials
      email
      customerNumber
      created
      lastAccess
      roles 
      address {
        address
        city
        postalCode
      }
      jokerProfile {
        alias
      }
    }
  }
}
