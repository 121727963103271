query GetUserWatchings($userId: String!) {
  getUserWatchings(userId: $userId) {
    author {
      refId
      label
    }
    horse {
      refId
      label
    }
    meet {
      refId
      label
    }
    topic {
      refId
      label
    }
  }
}
