query GetCardPaymentSuccessReport {
  list: getCardPaymentSuccessReport {
    total
    items {
      id: _id
      success
      failed
      failedRatio
      renewals
      endedSubscriptions
      failedFirstRenewals
      reasons {
        count
        description
      }
    }
  }
}
