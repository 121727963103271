query GetOrders($filter: OrderFilters, $sort: Sort, $page: Int, $limit: Int) {
  list: getOrders(filter: $filter, sort: $sort, page: $page, limit: $limit) {
    currentPage
    total
    items: orders {
      id
      orderStatus
      created
      label
      paymentMethod
      user {
        id
        email
      }
    }
  }
}
