import { useQuery } from '@apollo/client';
import { CircularProgress, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';

import ErrorMessage from '~/components/ErrorMessage';
import NoData from '~/components/NoData';
import TableList from '~/components/TableList';
import usePagination from '~/hooks/usePagination';

import query from './_GetDevicesByUser.gql';

export default function UserDevices({ user }) {
  const { page, pageSize, updatePage, updateRowSize } = usePagination(10);

  const { error, loading, data } = useQuery(query, {
    variables: { user, page: page, limit: pageSize },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (loading) {
    return <CircularProgress />;
  }

  const headers = ['Token'];

  const res = data?.getDevicesByUser;
  return (
    <TableList
      title="FCM Tokens - Mobilenheter"
      page={page}
      pageSize={pageSize}
      data={res}
      headers={headers}
      updatePage={updatePage}
      updateRowSize={updateRowSize}
    >
      {res?.userDevices.length > 0 ? (
        res.userDevices.map(userDevice => (
          <TableRow key={userDevice.id}>
            <TableCell>{userDevice.fcmToken}</TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={headers.length}>
            <NoData text="Den här användaren har inga FCM Tokens" />
          </TableCell>
        </TableRow>
      )}
    </TableList>
  );
}

UserDevices.propTypes = {
  user: PropTypes.string.isRequired,
};
