query GetSubscriptions(
  $page: Int
  $limit: Int
  $filter: SubscriptionsFilter
  $sort: Sort
) {
  list: getSubscriptions(
    page: $page
    limit: $limit
    filter: $filter
    sort: $sort
  ) {
    currentPage
    total
    items: subscriptions {
      id
      user {
        id
        email
      }
      product {
        id
        name
      }
      dueDate
      startDate
      endDate
      paymentMethod
      isActive
      isPaused
      pauses {
        subscription {
          startDate
          endDate
        }
      }
    }
  }
}
