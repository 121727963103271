import { useQuery } from '@apollo/client';
import { Box, CircularProgress } from '@mui/material';
import { useFormikContext } from 'formik';

import ErrorMessage from '~/components/ErrorMessage';
import Select from '~/components/Select';
import GetProducts from '~/utils/_GetProducts.gql';

import query from './_GetActiveCampaigns.gql';

export default function NotificationRenewalCampaigns() {
  const {
    data: productsData,
    loading: productsLoading,
    error: productsError,
  } = useQuery(GetProducts, {
    variables: {
      filter: {
        eligibleForRenewalOffer: true,
      },
    },
  });
  const products = productsData?.list?.items ?? [];

  const { data, loading, error } = useQuery(query);

  const campaigns = data?.campaigns || [];
  const { values, setFieldValue } = useFormikContext();

  if (loading || productsLoading) {
    return (
      <Box my={3}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {error && <ErrorMessage error={error} />}
      {productsError && <ErrorMessage error={productsError} />}
      {products.map(product => (
        <Box key={product.id} pt={1}>
          <Select
            label={product.name}
            value={values.notificationRenewalCampaigns?.[product.id] || ''}
            onChange={e => {
              setFieldValue(
                `notificationRenewalCampaigns.${product.id}`,
                e.target.value,
              );
            }}
            options={[
              { value: '', label: 'Ingen' },
              ...campaigns
                .filter(c => c.products.some(p => p.product.id === product.id))
                .map(campaign => ({
                  value: campaign.id,
                  label: campaign.name,
                })),
            ]}
          />
        </Box>
      ))}
    </>
  );
}
