#import "./_ProductInfoFragment.gql"

mutation EditProduct($id: String!, $input: ProductInput!) {
  editProduct(id: $id, input: $input) {
    ... on Product {
      ...ProductInfo
    }
    ... on Error {
      error
    }
  }
}
