query GetSubscriptionExpiryReports(
  $filter: SubscriptionExpiryFilters
  $page: Int
  $limit: Int
) {
  getSubscriptionExpiryReports(
    filter: $filter
    page: $page
    limit: $limit
  ) {
    currentPage
    total
    subscriptionExpiryReports {
      _id
      amount
    }
  }
}
