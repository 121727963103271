query getUnusedGiftCodePurchaseOrders($filter: GiftCodeOrderFilters, $page: Int, $limit: Int) {
  list: getUnusedGiftCodePurchaseOrders(filter: $filter, page: $page, limit: $limit) {
    currentPage
    total
    items: orders {
      giftCode
      created
      label
      totalPrice
      giftExpiryDate
      lineItems {
        product {
          id
        }
      }
    }
  }
}
