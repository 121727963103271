#import "./_ProductInfoFragment.gql"

mutation AddProduct($input: ProductInput!) {
  addProduct(input: $input) {
    ... on Product {
      ...ProductInfo
    }
    ... on Error {
      error
    }
  }
}
