query GetWatchGroups(
  $filter: WatchGroupFilter
  $sort: Sort
  $page: Int
  $limit: Int
) {
  list: getWatchGroups(
    sort: $sort
    filter: $filter
    page: $page
    limit: $limit
  ) {
    currentPage
    total
    items: watchGroups {
      id
      name
      users {
        id
        name
      }
      site
      created
    }
  }
}
